
export interface menu {
  header?: string;
  title?: string;
  icon?: string;
  to?: string;
  divider?: boolean;
  getURL?: boolean;
  chip?: string;
  chipColor?: string;
  chipVariant?: string;
  chipIcon?: string;
  children?: menu[];
  disabled?: boolean;
  type?: string;
  subCaption?: string;
}

const sidebarItem: menu[] = [
  {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    to: '/dashboard',
    // children: [
    //   {
    //     title: 'Overall',
    //     to: '/dashboard/overall'
    //   },
    //   {
    //     title: 'Environmental',
    //     to: '/dashboard/eia'
    //   },
    //   {
    //     title: 'EMS',
    //     to:'/dashboard/ems'
    //   },
    //   {
    //     title:'Assets',
    //     to:'/dashboard/assets'
    //   },
    //   {
    //     title:'HCM',
    //     to:'/dashboard/hcm',
    //     disabled:true,
    //   },
    //   {
    //     title:'MFG',
    //     to:'/dashboard/mfg',
    //     disabled:true,
    //   },

    // ]
  },

  {
    title: 'Alerts',
    icon: 'mdi-alert',
    to: '/alerts',
    // children: [
    //   {
    //     title: 'Overall',
    //     to: '/alerts/overall'
    //   },
    //   {
    //     title: 'Environmental',
    //     to: '/alerts/environment'
    //   },
    //   {
    //     title: 'EMS',
    //     to:'/alerts/energy'
    //   },
    //   {
    //     title:'Security',
    //     to:'/alerts/assets'
    //   }
    // ]
  },

  {
    title: 'Configuration',
    icon: 'mdi-cog',
    to: '/configuration',
    // children: [
    //   {
    //     title: 'Device',
    //     to: '/configuration/device'
    //   },
    //   {
    //     title: 'Location',
    //     to: '/configuration/location'
    //   },
    //   {
    //     title: 'Alert',
    //     to:'/configuration/alert'
    //   },

    // ]

  },
];

export default sidebarItem;
